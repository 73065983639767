import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import Home from "./pages/Home";
import Layout from "./components/Layout";
import Pricing from "./pages/Pricing";
import ContactUs from "./pages/ContactUs";
import Product1 from "./pages/Product1";
import Product2 from "./pages/Product2";

function App() {
  return (
    <BrowserRouter>
    <div>
      <Routes>
      <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="product-1" element={<Product1 />} />
          <Route path="product-2" element={<Product2 />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </div>
   </BrowserRouter>
  );
}

export default App;
