import React from 'react';

export default function Pricing () {

  const divStyle = {
    backgroundImage: 'url("/pricing/top-bg.png")',
    backgroundSize: 'cover', // Optional: Adjust based on your needs
    backgroundPosition: 'center', // Optional: Adjust based on your needs
    width: '100%', // Set width and height to define the size of the div
    height: '370px', // Set height to define the size of the div
  };

  return (
      <div className="max-w-[1440px]">
        <div className='w-full h-[370px]' style={divStyle}>
          <div className='pl-[110px] pt-[57px] w-[798px]'>
            <p className='text-[40px] text-zet-4 font-bold leading-[55px]'>
              Zetaby storage is priced 80% lower than traditional enterprise, local, and public cloud options.
            </p>
            <p className='mt-4 text-[22px] text-zet-4 leading-[40px]'>
              Simple | Predictable | Affordable
            </p>
          </div>
        </div>

        <div className='h-[962px] py-[90px] px-[110px] text-center justify-center items-center'>
          <p className='text-zet-3 text-[42px] font-bold'>Affordable pricing plans</p>
          <p className=' text-zet-5 text-lg leading-[30px]'>
            Choose from our budget-friendly pricing plans, providing cost-effective options and flexibility for your
          </p>
          <p className=' text-zet-5 text-lg leading-[30px]'>
            requirements
          </p>
          <div className='mt-[74px] px-[100px] flex flex-row gap-7 bg-zet-4 h-[586px] '>
            <div className='py-[50px] px-10 flex flex-col rounded-[24px] border-[3px] border-gray-100'>
              <p className='text-zet-1 text-[26px] font-bold text-start'>Basic Plan</p>
              <div className='mt-[30px] flex flex-row items-end h-[80px]'>
                <p className='text-zet-1 text-[58px] font-bold'>$.025</p>
                <p className='ml-5 text-zet-3 text-[40px] font-bold h-[70px]'>GB/month</p>
              </div>
              <div className='w-full h-[66px] rounded-[40px] bg-zet-1 flex justify-center items-center mt-[40px]'>
                <p className='text-zet-4 text-base font-bold'>Contact sale</p>
              </div>
              <div className='mt-[60px] flex flex-col justify-start gap-4 text-start'>
                <div className='flex flex-row'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
                    <g clip-path="url(#clip0_101_838)">
                    <path d="M13 26.1156C20.1799 26.1156 26 20.2955 26 13.1156C26 5.9357 20.1799 0.115601 13 0.115601C5.8201 0.115601 0 5.9357 0 13.1156C0 20.2955 5.8201 26.1156 13 26.1156Z" fill="#1CE783"/>
                    <path d="M7.11682 13.9561L10.4786 17.3179L18.8832 8.91333" stroke="white" strokeWidth="2" strokeLinecap="round" stroke-Linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_101_838">
                    <rect width="26" height="26" fill="white" transform="translate(0 0.115601)"/>
                    </clipPath>
                    </defs>
                  </svg>
                  <p className='ml-[14px] text-zet-3 text-base font-bold'>Basic support plan</p>
                </div>
                <div className='flex flex-row'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
                    <g clip-path="url(#clip0_101_838)">
                    <path d="M13 26.1156C20.1799 26.1156 26 20.2955 26 13.1156C26 5.9357 20.1799 0.115601 13 0.115601C5.8201 0.115601 0 5.9357 0 13.1156C0 20.2955 5.8201 26.1156 13 26.1156Z" fill="#1CE783"/>
                    <path d="M7.11682 13.9561L10.4786 17.3179L18.8832 8.91333" stroke="white" strokeWidth="2" strokeLinecap="round" stroke-Linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_101_838">
                    <rect width="26" height="26" fill="white" transform="translate(0 0.115601)"/>
                    </clipPath>
                    </defs>
                  </svg>
                  <p className='ml-[14px] text-zet-3 text-base font-bold'>No costs for data transfer out or  API requests</p>
                </div>
              </div>
            </div>

            <div className='py-[50px] px-10 flex flex-col rounded-[24px] bg-gray-100'>
              <p className='text-zet-1 text-[26px] font-bold text-start'>Enterprise Plan</p>
              <div className='mt-[30px] flex flex-row items-center h-[80px]'>
                <p className='text-zet-1 text-[38px] font-bold'>Greater discounts</p>
              </div>
              <div className='w-full h-[66px] rounded-[40px] bg-zet-1 flex justify-center items-center mt-[40px]'>
                <p className='text-zet-4 text-base font-bold'>Contact sale</p>
              </div>
              <div className='mt-[60px] flex flex-col justify-start gap-4 text-start'>
                <div className='flex flex-row'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
                    <g clip-path="url(#clip0_101_838)">
                    <path d="M13 26.1156C20.1799 26.1156 26 20.2955 26 13.1156C26 5.9357 20.1799 0.115601 13 0.115601C5.8201 0.115601 0 5.9357 0 13.1156C0 20.2955 5.8201 26.1156 13 26.1156Z" fill="#1CE783"/>
                    <path d="M7.11682 13.9561L10.4786 17.3179L18.8832 8.91333" stroke="white" strokeWidth="2" strokeLinecap="round" stroke-Linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_101_838">
                    <rect width="26" height="26" fill="white" transform="translate(0 0.115601)"/>
                    </clipPath>
                    </defs>
                  </svg>
                  <p className='ml-[14px] text-zet-3 text-base font-bold'>Premium support plan</p>
                </div>
                <div className='flex flex-row'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
                    <g clip-path="url(#clip0_101_838)">
                    <path d="M13 26.1156C20.1799 26.1156 26 20.2955 26 13.1156C26 5.9357 20.1799 0.115601 13 0.115601C5.8201 0.115601 0 5.9357 0 13.1156C0 20.2955 5.8201 26.1156 13 26.1156Z" fill="#1CE783"/>
                    <path d="M7.11682 13.9561L10.4786 17.3179L18.8832 8.91333" stroke="white" strokeWidth="2" strokeLinecap="round" stroke-Linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_101_838">
                    <rect width="26" height="26" fill="white" transform="translate(0 0.115601)"/>
                    </clipPath>
                    </defs>
                  </svg>
                  <p className='ml-[14px] text-zet-3 text-base font-bold'>Purchase in 1, 3 or 5 year increments</p>
                </div>
                <div className='flex flex-row'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
                    <g clip-path="url(#clip0_101_838)">
                    <path d="M13 26.1156C20.1799 26.1156 26 20.2955 26 13.1156C26 5.9357 20.1799 0.115601 13 0.115601C5.8201 0.115601 0 5.9357 0 13.1156C0 20.2955 5.8201 26.1156 13 26.1156Z" fill="#1CE783"/>
                    <path d="M7.11682 13.9561L10.4786 17.3179L18.8832 8.91333" stroke="white" strokeWidth="2" strokeLinecap="round" stroke-Linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_101_838">
                    <rect width="26" height="26" fill="white" transform="translate(0 0.115601)"/>
                    </clipPath>
                    </defs>
                  </svg>
                  <p className='ml-[14px] text-zet-3 text-base font-bold'>No costs for data transfer out or  API requests</p>
                </div>
                <div className='flex flex-row'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
                    <g clip-path="url(#clip0_101_838)">
                    <path d="M13 26.1156C20.1799 26.1156 26 20.2955 26 13.1156C26 5.9357 20.1799 0.115601 13 0.115601C5.8201 0.115601 0 5.9357 0 13.1156C0 20.2955 5.8201 26.1156 13 26.1156Z" fill="#1CE783"/>
                    <path d="M7.11682 13.9561L10.4786 17.3179L18.8832 8.91333" stroke="white" strokeWidth="2" strokeLinecap="round" stroke-Linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_101_838">
                    <rect width="26" height="26" fill="white" transform="translate(0 0.115601)"/>
                    </clipPath>
                    </defs>
                  </svg>
                  <p className='ml-[14px] text-zet-3 text-base font-bold'>Greater discounts for term & capacity</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='h-[680px] py-[90px] px-[110px] text-center justify-center items-center bg-gray-100'>
          <p className='text-zet-3 text-[42px] font-bold'>No costs for outbound data transfer or API</p>
          <p className='text-zet-3 text-[42px] font-bold'>requests.</p>
          <p className=' text-zet-5 text-lg leading-[30px]'>
            Zetaby's pricing at $.025 per GB/month comes with no extra fees for egress or API requests, ensuring free
          </p>
          <p className=' text-zet-5 text-lg leading-[30px]'>
            data access.
          </p>
          <div className='mt-[50px] gap-[64px] flex flex-row'>
            <div className='flex flex-col items-center w-[364px]'>
            <svg xmlns="http://www.w3.org/2000/svg" width="160" height="161" viewBox="0 0 160 161" fill="none">
              <circle cx="80" cy="80.1155" r="80" fill="white"/>
              <path d="M108.003 80.2805L78.2372 50.515C77.9815 50.2593 77.6347 50.1156 77.2731 50.1155H51.364C51.0024 50.1155 50.6555 50.2591 50.3998 50.5149C50.1441 50.7706 50.0004 51.1175 50.0004 51.4791V77.3882C50.0005 77.7498 50.1442 78.0966 50.3999 78.3523L62.2731 90.2255V108.752C62.2731 109.114 62.4168 109.46 62.6725 109.716C62.9282 109.972 63.2751 110.115 63.6368 110.115C63.9984 110.115 64.3453 109.972 64.601 109.716C64.8567 109.46 65.0004 109.114 65.0004 108.752V71.7414C63.7149 71.4095 62.5947 70.6202 61.8496 69.5214C61.1045 68.4226 60.7857 67.0898 60.953 65.7728C61.1202 64.4557 61.7621 63.2449 62.7583 62.3673C63.7544 61.4897 65.0364 61.0055 66.364 61.0055C67.6916 61.0055 68.9737 61.4897 69.9698 62.3673C70.9659 63.2449 71.6078 64.4557 71.7751 65.7728C71.9424 67.0898 71.6236 68.4226 70.8785 69.5214C70.1334 70.6202 69.0131 71.4095 67.7277 71.7414V95.68L80.1654 108.118C81.444 109.396 83.1783 110.115 84.9865 110.115C86.7948 110.115 88.529 109.396 89.8077 108.118L108.003 89.9228C109.281 88.6441 110 86.9099 110 85.1016C110 83.2933 109.281 81.5591 108.003 80.2805Z" fill="#093889"/>
            </svg>
              <p className='mt-8 text-zet-3 text-[24px] font-bold'>80% less than others cloud</p>
            </div>

            <div className='flex flex-col items-center w-[364px]'>
            <svg xmlns="http://www.w3.org/2000/svg" width="160" height="161" viewBox="0 0 160 161" fill="none">
              <circle cx="80" cy="80.1155" r="80" fill="white"/>
              <path d="M53.0933 76.8701L53.1123 77.3338L52.6512 77.3872C45.819 78.1771 40.5 83.9976 40.5 91.0342C40.5 98.6143 46.6636 104.778 54.2438 104.778H104.525C112.78 104.778 119.5 98.0579 119.5 89.8092C119.5 83.5027 115.502 77.8829 109.648 75.7541L109.342 75.6428L109.32 75.3179C108.944 69.7473 104.291 65.3342 98.6313 65.3342C96.4986 65.3342 94.4692 65.9551 92.7316 67.1016L92.2591 67.4133L92.0081 66.9059C88.5788 59.9741 81.4323 55.453 73.6125 55.453C62.2884 55.453 53.0687 64.6668 53.0687 75.9905C53.0687 76.268 53.0804 76.553 53.0928 76.8565L53.0933 76.8701Z" fill="#093889" stroke="#093889"/>
              <path d="M80.6667 79.3155C83.04 79.3155 83.92 80.4488 84 82.1155H86.9467C86.8533 79.8221 85.4533 77.7155 82.6667 77.0355V74.1155H78.6667V76.9955C77.96 77.1555 77.2933 77.3955 76.6933 77.7155L78.6533 79.6755C79.2 79.4488 79.8667 79.3155 80.6667 79.3155ZM71.1067 75.5288L69.4133 77.2221L74 81.8088C74 84.5821 76.08 86.0888 79.2133 87.0221L83.8933 91.7021C83.44 92.3421 82.4933 92.9155 80.6667 92.9155C77.92 92.9155 76.84 91.6888 76.6933 90.1155H73.76C73.92 93.0355 76.1067 94.6755 78.6667 95.2221V98.1155H82.6667V95.2488C83.9467 95.0088 85.0933 94.5155 85.9333 93.7555L88.8933 96.7155L90.5867 95.0221L71.1067 75.5288Z" fill="white"/>
            </svg>
              <p className='mt-8 text-zet-3 text-[24px] font-bold'>No costs for data transfer out</p>
            </div>

            <div className='flex flex-col items-center w-[364px]'>
              <svg xmlns="http://www.w3.org/2000/svg" width="160" height="161" viewBox="0 0 160 161" fill="none">
                <circle cx="80" cy="80.1155" r="80" fill="white"/>
                <path d="M71.0662 79.4694C71.0662 77.1 72.0074 74.8277 73.6828 73.1523C75.3583 71.4769 77.6306 70.5356 80 70.5356C82.3694 70.5356 84.6417 71.4769 86.3172 73.1523C87.9926 74.8277 88.9338 77.1 88.9338 79.4694H109.354V75.6406C109.355 75.3197 109.235 75.0102 109.018 74.7737C108.801 74.5372 108.503 74.391 108.184 74.3644L100.931 73.7633C100.452 71.9976 99.7477 70.3007 98.8363 68.7144L103.547 63.1474C103.754 62.903 103.861 62.5899 103.848 62.27C103.835 61.9502 103.702 61.647 103.475 61.4206L98.0488 56.0054C97.8224 55.7791 97.5192 55.6462 97.1994 55.6329C96.8796 55.6197 96.5664 55.7271 96.3221 55.9339L90.7563 60.6433C89.1695 59.7295 87.4723 59.0223 85.7062 58.5388L85.105 51.2858C85.0784 50.966 84.9323 50.668 84.6957 50.4511C84.4592 50.2342 84.1497 50.1144 83.8288 50.1155H76.1712C75.8503 50.1144 75.5408 50.2342 75.3043 50.4511C75.0677 50.668 74.9216 50.966 74.895 51.2858L74.2938 58.5388C72.5282 59.0178 70.8313 59.7217 69.245 60.6331L63.6779 55.9276C63.4336 55.7207 63.1205 55.6133 62.8006 55.6265C62.4808 55.6398 62.1776 55.7727 61.9512 55.999L56.536 61.4206C56.3097 61.647 56.1767 61.9502 56.1635 62.27C56.1503 62.5899 56.2577 62.903 56.4645 63.1474L61.1739 68.7131C60.2601 70.3 59.5528 71.9971 59.0694 73.7633L51.8164 74.3644C51.4966 74.391 51.1986 74.5372 50.9817 74.7737C50.7648 75.0102 50.645 75.3197 50.6461 75.6406V79.4694H71.0662Z" fill="#093889"/>
                <path d="M62.1324 82.0219V92.9799L57.3923 97.7199C56.8809 97.4707 56.32 97.3398 55.7511 97.337C54.9938 97.337 54.2536 97.5616 53.6239 97.9823C52.9943 98.403 52.5035 99.001 52.2138 99.7006C51.924 100.4 51.8481 101.17 51.9959 101.913C52.1436 102.655 52.5083 103.338 53.0437 103.873C53.5792 104.409 54.2614 104.773 55.0041 104.921C55.7468 105.069 56.5167 104.993 57.2163 104.703C57.9159 104.413 58.5139 103.923 58.9346 103.293C59.3553 102.663 59.5799 101.923 59.5799 101.166C59.5771 100.597 59.4462 100.036 59.197 99.5245L64.3109 94.4106C64.5503 94.1713 64.6848 93.8467 64.6849 93.5082V82.0219H62.1324Z" fill="#093889"/>
                <path d="M87.6575 102.677V82.0219H85.105V102.677C84.2535 102.978 83.5358 103.57 83.0788 104.349C82.6218 105.128 82.4549 106.044 82.6076 106.934C82.7604 107.824 83.2229 108.632 83.9134 109.214C84.604 109.796 85.4781 110.115 86.3813 110.115C87.2845 110.115 88.1586 109.796 88.8491 109.214C89.5397 108.632 90.0022 107.824 90.1549 106.934C90.3076 106.044 90.1408 105.128 89.6838 104.349C89.2268 103.57 88.5091 102.978 87.6575 102.677Z" fill="#093889"/>
                <path d="M74.895 96.2956V82.0219H72.3424V96.2956C71.4909 96.5967 70.7732 97.1891 70.3162 97.9681C69.8592 98.7471 69.6923 99.6627 69.8451 100.553C69.9978 101.443 70.4603 102.251 71.1508 102.833C71.8414 103.415 72.7155 103.734 73.6187 103.734C74.5219 103.734 75.396 103.415 76.0865 102.833C76.7771 102.251 77.2396 101.443 77.3923 100.553C77.5451 99.6627 77.3782 98.7471 76.9212 97.9681C76.4642 97.1891 75.7465 96.5967 74.895 96.2956Z" fill="#093889"/>
                <path d="M104.249 97.337C103.68 97.3398 103.119 97.4707 102.608 97.7199L97.8676 92.9799V82.0219H95.3151V93.5082C95.3151 93.8467 95.4496 94.1713 95.689 94.4106L100.803 99.5245C100.554 100.036 100.423 100.597 100.42 101.166C100.42 101.923 100.645 102.663 101.065 103.293C101.486 103.923 102.084 104.413 102.784 104.703C103.483 104.993 104.253 105.069 104.996 104.921C105.739 104.773 106.421 104.409 106.956 103.873C107.492 103.338 107.856 102.655 108.004 101.913C108.152 101.17 108.076 100.4 107.786 99.7006C107.496 99.001 107.006 98.403 106.376 97.9823C105.746 97.5616 105.006 97.337 104.249 97.337Z" fill="#093889"/>
              </svg>
              <p className='mt-8 text-zet-3 text-[24px] font-bold'>No API request charges</p>
            </div>
          </div>
        </div>  
      </div>
  )
};