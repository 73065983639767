import React from 'react';

export default function Product1 () {
  const divStyle = {
    backgroundImage: 'url("/product1/top-bg.png")',
    backgroundSize: 'cover', // Optional: Adjust based on your needs
    backgroundPosition: 'center', // Optional: Adjust based on your needs
    width: '100%', // Set width and height to define the size of the div
    height: '370px', // Set height to define the size of the div
  };
  return (
      <div className="max-w-[1440px]">
        <div className='w-full h-[370px]' style={divStyle}>
          <div className='pl-[110px] pt-[84px] w-[726px]'>
            <p className='text-[52px] text-zet-4 font-bold leading-[66px]'>
              S3 Object Storage
            </p>
            <p className='mt-4 text-[22px] text-zet-4 leading-[40px]'>
              Zetabyi's Hot Cloud Storage offers fast, affordable, and secure cloud storage without extra fees for egress or API calls, providing transparent pricing.
            </p>
          </div>
        </div> 

        <div className='h-[680px] py-[90px] px-[110px] text-center justify-center items-center'>
          <p className='text-zet-3 text-[42px] font-bold'>Cloud Storage that is simple, predictable,</p>
          <p className='text-zet-3 text-[42px] font-bold'>affordable and secure</p>
          <div className='mt-[50px] gap-[64px] flex flex-row'>
            <div className='flex flex-col items-center w-[364px]'>
              <svg xmlns="http://www.w3.org/2000/svg" width="160" height="161" viewBox="0 0 160 161" fill="none">
                <circle cx="80" cy="80.5378" r="80" fill="#F4F5F6"/>
                <path d="M74 50.5378C57.431 50.5378 44 63.9688 44 80.5378C44 97.1068 57.431 110.538 74 110.538C90.569 110.538 104 97.1068 104 80.5378C104 63.9688 90.569 50.5378 74 50.5378ZM96.5117 52.4714C104.729 59.0684 110 69.1798 110 80.5378C110 91.8958 104.729 102.007 96.5117 108.604C107.888 104.341 116 93.4018 116 80.5378C116 67.6738 107.888 56.7344 96.5117 52.4714ZM71 62.5378H77V67.3835C81.05 68.2895 83.7586 71.0491 83.8906 74.7781H78.834C78.585 72.7501 76.6976 71.3972 74.0996 71.3972C71.4116 71.3972 69.6348 72.6433 69.6348 74.6023C69.6348 76.1863 70.8562 77.0981 73.8652 77.7371L76.9648 78.3933C82.1278 79.4793 84.3477 81.7101 84.3477 85.6941C84.3477 89.9841 81.596 92.8602 77 93.7332V98.5378H71V93.7449C66.587 92.9169 63.8143 90.2079 63.6523 86.2449H68.8438C69.0928 88.3269 71.1957 89.6609 74.2227 89.6609C77.0157 89.6609 78.9922 88.3089 78.9922 86.3679C78.9922 84.7299 77.7125 83.7881 74.5625 83.1101L71.2168 82.3953C66.5368 81.4173 64.2383 78.9802 64.2383 75.0652C64.2383 71.1262 66.845 68.3193 71 67.3953V62.5378Z" fill="#093889"/>
              </svg>
              <p className='mt-8 text-zet-3 text-[26px] font-bold'>Price</p>
              <p className='mt-2 text-zet-5 text-lg leading-[30px]'>
                Save over 80% compared to local, public cloud storage, with no complicated tiers or additional fees for egress or API calls
              </p>
            </div>

            <div className='flex flex-col items-center w-[364px]'>
              <svg xmlns="http://www.w3.org/2000/svg" width="160" height="161" viewBox="0 0 160 161" fill="none">
                <circle cx="80" cy="80.5378" r="80" fill="#F4F5F6"/>
                <path d="M110 50.5378C110 50.5378 87.5336 50.4841 70.0179 67.9998C69.2092 68.8085 68.5051 69.6888 67.8676 70.5997C64.7621 70.3514 60.0084 70.4326 57.5751 72.8674C51.8951 78.5475 50 88.0177 50 88.0177L62.5112 86.229V90.5199L70.0179 98.0266H74.3089L72.5202 110.538C72.5202 110.538 81.9904 108.643 87.6704 102.963C90.1053 100.529 90.1864 95.7758 89.9381 92.6703C90.849 92.0328 91.7294 91.3286 92.5381 90.5199C110.054 73.0042 110 50.5378 110 50.5378ZM90.0358 65.4975C92.8008 65.4975 95.0403 67.737 95.0403 70.502C95.0403 73.267 92.8008 75.5065 90.0358 75.5065C87.2709 75.5065 85.0314 73.267 85.0314 70.502C85.0314 67.737 87.2709 65.4975 90.0358 65.4975ZM60.5075 93.0124C59.5566 93.2626 58.6555 93.7403 57.9124 94.4834C54.4943 97.9015 55.0387 105.494 55.0387 105.494C55.0387 105.494 62.5815 106.084 66.0446 102.621C66.7878 101.877 67.2655 100.971 67.5157 100.021L66.3721 98.877C66.2595 99.0372 66.1898 99.2231 66.0446 99.3657C63.605 101.805 59.9454 100.588 59.9454 100.588C59.9454 100.588 58.725 96.928 61.1672 94.4883C61.3124 94.3432 61.4909 94.276 61.6511 94.1609L60.5075 93.0124Z" fill="#093889"/>
              </svg>
              <p className='mt-8 text-zet-3 text-[26px] font-bold'>Performance</p>
              <p className='mt-2 text-zet-5 text-lg leading-[30px]'>
                Fast, stable download & upload speed, unrestricted bandwidth for seamless performance.
              </p>
            </div>

            <div className='flex flex-col items-center w-[364px]'>
              <svg xmlns="http://www.w3.org/2000/svg" width="160" height="161" viewBox="0 0 160 161" fill="none">
                <circle cx="80" cy="80.5378" r="80" fill="#F4F5F6"/>
                <path d="M77.7661 51.0124L58.5102 59.5702C56.5213 60.4533 55.2422 62.4229 55.2422 64.5988V77.5277C55.2422 99.0668 72.9549 109.124 79.9998 110.538C87.0447 109.124 104.757 99.0668 104.757 77.5277V64.5988C104.757 62.4256 103.478 60.4533 101.489 59.5702L82.2335 51.0124C80.8113 50.3797 79.1883 50.3797 77.7661 51.0124ZM75.3041 90.4759L67.6842 82.8561C66.6114 81.7833 66.6114 80.0392 67.6842 78.9664C68.7571 77.8936 70.5011 77.8936 71.5739 78.9664L77.2489 84.6414L91.237 70.6534C92.3098 69.5805 94.0538 69.5805 95.1267 70.6534C96.1995 71.7262 96.1995 73.4702 95.1267 74.5431L79.1938 90.4759C78.1209 91.5488 76.3769 91.5488 75.3041 90.4759Z" fill="#093889"/>
              </svg>
              <p className='mt-8 text-zet-3 text-[26px] font-bold'>Protection</p>
              <p className='mt-2 text-zet-5 text-lg leading-[30px]'>
                Ensure data center redundancy, bucket replication, and data immutability for robust data security and integrity.
              </p>
            </div>
          </div>
        </div> 

        <div className='bg-gray-100 py-[90px] px-[110px]'>
          <p className='text-zet-3 text-[42px] font-bold text-center'>Capabilities and Benefits</p>
          {/* section */}
          <div className='h-[404px] py-[60px] flex flex-row'>
            <div className='w-[600px] flex flex-col justify-center'>
              <p className='text-zet-3 text-[34px] font-bold'>Native S3 API Compatibility</p>
              <p className='mt-4 text-zet-5 text-base leading-[30px]'>Designed specifically for full S3 API compatibility, Cloudian ensures seamless integration with your S3 applications</p>
            </div>
            <img className='ml-[100px]' src="/product1/sec1.png" width={520} height={284} alt="" />
          </div>

          {/* section */}
          <div className='h-[404px] py-[60px] flex flex-row'>
          <img className='ml-[100px]' src="/product1/sec2.png" width={520} height={284} alt="" />
            <div className='w-[600px] flex flex-col justify-center'>
              <p className='text-zet-3 text-[34px] font-bold'>Unified File and Object</p>
              <p className='mt-4 text-zet-5 text-base leading-[30px]'>Centralize files and objects in a unified storage environment for streamlined management and cost reduction.</p>
            </div>
          </div>

          {/* section */}
          <div className='h-[404px] py-[60px] flex flex-row'>
            <div className='w-[600px] flex flex-col justify-center'>
              <p className='text-zet-3 text-[34px] font-bold'>High Performance</p>
              <p className='mt-4 text-zet-5 text-base leading-[30px]'>Fast, stable download & upload speed, unrestricted bandwidth for seamless performance.</p>
            </div>
            <img className='ml-[100px]' src="/product1/sec3.png" width={520} height={284} alt="" />
          </div>

          {/* section */}
          <div className='h-[404px] py-[60px] flex flex-row'>
          <img className='ml-[100px]' src="/product1/sec4.png" width={520} height={284} alt="" />
            <div className='w-[600px] flex flex-col justify-center'>
              <p className='text-zet-3 text-[34px] font-bold'>Complete Visibility</p>
              <p className='mt-4 text-zet-5 text-base leading-[30px]'>View storage, networking and server metrics with Zetaby observability and analytics software. Detect trends and identify anomalies proactively to prevent issues</p>
            </div>
          </div>

          {/* section */}
          <div className='h-[404px] py-[60px] flex flex-row'>
            <div className='w-[600px] flex flex-col justify-center'>
              <p className='text-zet-3 text-[34px] font-bold'>Multi-tenancy</p>
              <p className='mt-4 text-zet-5 text-base leading-[30px]'>Enable secure storage for multiple independent users with distinct environments in a single S3 object storage system</p>
            </div>
            <img className='ml-[100px]' src="/product1/sec5.png" width={520} height={284} alt="" />
          </div>
        </div>
      </div>
  )
};