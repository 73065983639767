import React, {useState} from 'react';
import { Link } from 'react-router-dom';

export default function Header () {

  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="w-[1440px] px-[110px] flex items-center flex-row h-[110px]">
      <Link to='/'>
        <img src="/favicon.svg" alt="logo" />
      </Link>
      <div className='flex flex-row ml-[120px] gap-[45px] text-zet-3 font-bold text-lg'>
        <div className='flex flex-row items-center cursor-pointer' onClick={() => {setShowMenu(!showMenu)}}>
          Products
          { showMenu ?
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
              <path d="M4.5874 13.4905L10.4207 7.65714L16.2541 13.4905" stroke="#00122B" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          :
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
              <path d="M4.48096 7.19873L10.3143 13.0321L16.1476 7.19873" stroke="#00122B" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          }
          { showMenu && <div className='absolute flex flex-col items-center w-[206px] mt-[150px] h-[134px] z-10 rounded-[4px] bg-white ml-[50px]'>
            <Link to='/product-1' className='text-zet-3 text-[14px] font-bold h-[67px] w-full flex justify-center text-center items-center'>
              S3 Objeject Storage
            </Link>
            <div className='h-[1px] bg-zet-5 w-[155px] opacity-20'></div>
            <Link to='/product-2' className='text-zet-3 text-[14px] font-bold h-[67px] w-full flex justify-center text-center items-center'>
              Surveillance Storage
            </Link>
          </div> }
        </div>
        <Link to='/pricing'>Pricing</Link>
        <Link to='/contact-us'>Contact us</Link>
      </div>

      <div className='h-full ml-auto flex flex-row items-center text-zet-3 text-lg'>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
          <path d="M12.2514 17.5006L12.2605 17.507C13.0476 18.0081 13.982 18.2257 14.9095 18.1239C15.837 18.022 16.7019 17.6068 17.3615 16.9468L17.9344 16.3738C18.0614 16.2469 18.1621 16.0963 18.2308 15.9305C18.2995 15.7646 18.3349 15.5869 18.3349 15.4074C18.3349 15.2279 18.2995 15.0501 18.2308 14.8843C18.1621 14.7185 18.0614 14.5678 17.9344 14.4409L15.5178 12.0262C15.3909 11.8992 15.2403 11.7985 15.0745 11.7298C14.9086 11.6611 14.7309 11.6257 14.5514 11.6257C14.3719 11.6257 14.1941 11.6611 14.0283 11.7298C13.8625 11.7985 13.7118 11.8992 13.5849 12.0262C13.3287 12.2823 12.9813 12.4262 12.619 12.4262C12.2567 12.4262 11.9092 12.2823 11.653 12.0262L7.78901 8.16134C7.53287 7.90511 7.38897 7.55765 7.38897 7.19535C7.38897 6.83305 7.53287 6.48558 7.78901 6.22936C7.91598 6.10247 8.0167 5.95181 8.08542 5.78599C8.15414 5.62016 8.18951 5.44242 8.18951 5.26292C8.18951 5.08341 8.15414 4.90567 8.08542 4.73985C8.0167 4.57402 7.91598 4.42336 7.78901 4.29647L5.37336 1.88173C5.11714 1.62558 4.76967 1.48169 4.40737 1.48169C4.04507 1.48169 3.69761 1.62558 3.44138 1.88173L2.86753 2.45467C2.20768 3.11431 1.79262 3.97933 1.69093 4.90679C1.58924 5.83425 1.80701 6.76866 2.30825 7.5556L2.31371 7.56471C4.9609 11.4813 8.33434 14.8541 12.2514 17.5006V17.5006Z" stroke="#00122B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        <p className='ml-1'>Call: +84 968 891 509</p>
        <div className='ml-4 w-[90px] h-[55px] flex items-center justify-center bg-zet-2 rounded-[30px] text-base font-bold'>
          Login
        </div>
      </div>
    </div>
  )
};