import React from 'react';

export default function ContactUs () {
  return (
      <div className="max-w-[1440px] pt-[98px] px-[110px]">
        <div className='flex flex-row'>
          <div className='w-[502px]'>
            <p className='uppercase text-zet-5 text-base font-bold'>Contact us</p>
            <p className='mt-2 text-zet-3 font-bold text-[42px]'>Get in touch today</p>
            <p className='mt-3 text-zet-5 text-base'>Ready to discover Zetaby can do for you? Share your use case, and our cloud storage experts will be in touch soon</p>
            <div className='flex flex-row mt-[52px] items-center'>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <path d="M18.3333 6.06506V14.3984C18.3333 14.9509 18.1138 15.4808 17.7231 15.8715C17.3324 16.2622 16.8025 16.4817 16.25 16.4817H3.74996C3.19743 16.4817 2.66752 16.2622 2.27682 15.8715C1.88612 15.4808 1.66663 14.9509 1.66663 14.3984V6.06506" stroke="#606D85" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M18.3333 6.06502C18.3333 5.51249 18.1138 4.98258 17.7231 4.59188C17.3324 4.20118 16.8025 3.98169 16.25 3.98169H3.74996C3.19743 3.98169 2.66752 4.20118 2.27682 4.59188C1.88612 4.98258 1.66663 5.51249 1.66663 6.06502L8.89579 10.5789C9.2269 10.7859 9.6095 10.8956 9.99996 10.8956C10.3904 10.8956 10.773 10.7859 11.1041 10.5789L18.3333 6.06502Z" stroke="#606D85" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <p className='ml-[6px] text-zet-5 text-base'>sales@zetaby.com</p>
            </div>
            <div className='flex flex-row mt-[26px] items-center'>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <g clip-path="url(#clip0_101_1016)">
                  <path d="M12.2515 17.5006L12.2606 17.507C13.0477 18.0081 13.9822 18.2257 14.9096 18.1239C15.8371 18.022 16.702 17.6068 17.3616 16.9468L17.9345 16.3738C18.0615 16.2469 18.1622 16.0963 18.2309 15.9305C18.2996 15.7646 18.335 15.5869 18.335 15.4074C18.335 15.2279 18.2996 15.0501 18.2309 14.8843C18.1622 14.7185 18.0615 14.5678 17.9345 14.4409L15.518 12.0262C15.3911 11.8992 15.2404 11.7985 15.0746 11.7298C14.9088 11.6611 14.731 11.6257 14.5515 11.6257C14.372 11.6257 14.1943 11.6611 14.0284 11.7298C13.8626 11.7985 13.712 11.8992 13.5851 12.0262C13.3288 12.2823 12.9814 12.4262 12.6191 12.4262C12.2568 12.4262 11.9093 12.2823 11.6531 12.0262L7.78914 8.16134C7.53299 7.90511 7.3891 7.55765 7.3891 7.19535C7.3891 6.83305 7.53299 6.48558 7.78914 6.22936C7.9161 6.10247 8.01682 5.95181 8.08554 5.78599C8.15426 5.62016 8.18963 5.44242 8.18963 5.26292C8.18963 5.08341 8.15426 4.90567 8.08554 4.73985C8.01682 4.57402 7.9161 4.42336 7.78914 4.29647L5.37348 1.88173C5.11726 1.62558 4.76979 1.48169 4.40749 1.48169C4.04519 1.48169 3.69773 1.62558 3.44151 1.88173L2.86765 2.45467C2.2078 3.11431 1.79274 3.97933 1.69105 4.90679C1.58936 5.83425 1.80713 6.76866 2.30837 7.5556L2.31384 7.56471C4.96102 11.4813 8.33446 14.8541 12.2515 17.5006V17.5006Z" stroke="#606D85" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_101_1016">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.115479)"/>
                  </clipPath>
                </defs>
              </svg>
              <p className='ml-[6px] text-zet-5 text-base'>(+84) 968 891 509</p>
            </div>
            <div className='flex flex-row mt-[26px] items-center'>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <path d="M16.8608 9.07166C16.8608 14.4505 11.3516 17.7642 10.2067 18.3954C10.1433 18.4304 10.072 18.4488 9.99961 18.4488C9.92718 18.4488 9.85593 18.4304 9.79251 18.3954C8.64676 17.7642 3.13928 14.4505 3.13928 9.07166C3.13928 4.78369 5.71207 1.7821 10 1.7821C14.288 1.7821 16.8608 4.78369 16.8608 9.07166Z" stroke="#606D85" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.5697 8.6429C6.5697 9.5527 6.93112 10.4252 7.57444 11.0686C8.21776 11.7119 9.09029 12.0733 10.0001 12.0733C10.9099 12.0733 11.7824 11.7119 12.4257 11.0686C13.069 10.4252 13.4305 9.5527 13.4305 8.6429C13.4305 7.73311 13.069 6.86058 12.4257 6.21726C11.7824 5.57394 10.9099 5.21252 10.0001 5.21252C9.09029 5.21252 8.21776 5.57394 7.57444 6.21726C6.93112 6.86058 6.5697 7.73311 6.5697 8.6429V8.6429Z" stroke="#606D85" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <p className='ml-[6px] text-zet-5 text-base'>20 Le Thi Cho, Phu Thuan Ward, District 7, Ho Chi Minh City, Vietnam</p>
            </div>
          </div>
          <div className='ml-auto w-[616px] bg-gray-100 rounded-[24px] px-[40px] py-[65px] flex flex-col gap-8'>
            <div className='flex flex-row'>
              <div className='w-[252px]'>
                <p className='text-zet-3 text-base font-bold'>Name</p>
                <input className='w-full h-[72px] rounded-[50px] mt-3 pl-6 text-zet-5' type="text" />
              </div>

              <div className='w-[252px] ml-auto'>
                <p className='text-zet-3 text-base font-bold'>Phone</p>
                <input className='w-full h-[72px] rounded-[50px] mt-3 pl-6 text-zet-5' type="text" />
              </div>
            </div>

            <div className='w-full'>
              <p className='text-zet-3 text-base font-bold'>Email</p>
              <input className='w-full h-[72px] rounded-[50px] mt-3 pl-6 text-zet-5' type="text" />
            </div>

            <div className='w-full'>
              <p className='text-zet-3 text-base font-bold'>Company</p>
              <input className='w-full h-[72px] rounded-[50px] mt-3 pl-6 text-zet-5' type="text" />
            </div>

            <div className='w-full'>
              <p className='text-zet-3 text-base font-bold'>Message</p>
              <textarea className='w-full h-[142px] rounded-[20px] mt-3 pl-6 pt-6 text-zet-5' type="text" />
            </div>

            <div className='w-full h-[66px] bg-zet-1 rounded-[40px] flex justify-center items-center'>
              <p className='text-zet-4 font-bold'>Submit</p>
            </div>
          </div>
        </div>
      </div>
  )
};