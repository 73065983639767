import React from 'react';
import { Outlet } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
export default function Layout () {
  return (
    <div className='w-screen flex flex-col items-center font-sans'>
      <Header />
      <div className='w-[1440px]'>
        <Outlet />
      </div>
      <Footer />
    </div>
  )
};