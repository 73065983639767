import React from 'react';
import { Link } from 'react-router-dom';

export default function ContactUsBtn () {
  return(
    <Link to='/contact-us' className='w-[170px] h-[66px] rounded-[40px] bg-zet-2 flex justify-center items-center cursor-pointer'>
      <p className='text-black text-lg font-bold'>
        Contact us
      </p>
    </Link>
  )
}